<template>
  <v-dialog
    :value="printOrdersModal"
    persistent
    fullscreen
    @keydown.enter="print"
  >
    <v-card>
      <v-card-title>Print orders</v-card-title>
      <v-row>
        <v-col cols="6">
          <input
            class="hidden"
            id="input"
            @focus="closePrintTotalsModal"
            type="text"
          />
          <fieldset>
            <legend>Status</legend>
            <v-select
              v-model="selectedStatus"
              :items="statuses"
              item-text="name"
              item-value="value"
              @change="selectStatus"
              clearable
            ></v-select>
          </fieldset>
          <fieldset>
            <legend>Routes</legend>
            <div class="d-flex align-center">
              <v-checkbox
                :value="allRoutes"
                @change="selectAllRoutes"
                label="Alles selecteren"
              ></v-checkbox>
            </div>
            <div class="d-flex flex-wrap">
              <v-checkbox
                v-for="route of routes"
                :key="route"
                v-model="selectedRoutes"
                :label="route"
                :value="route"
                @change="selectRoute"
              ></v-checkbox>
            </div>
          </fieldset>
          <div class="settings">            
            <template v-if="isElectron">              
              <label>Printer:</label
              ><select v-model="options.printer"
                ><option
                  v-for="printer of printers"
                  :key="printer.name"
                  :value="printer.name"
                >
                  {{ printer.displayName }}
                </option>
              </select>
              <label>Aantal:</label
              ><input type="number" v-model="options.copies" />
              <label>Formaat:</label
              ><select v-model="options.pageSize"
                ><option
                  v-for="pageSize of pageSizes"
                  :key="pageSize.value"
                  :value="pageSize.value"
                >
                  {{ pageSize.text }}
                </option>
              </select>
            </template>
          </div>
        </v-col>
        <v-col cols="6">
          <iframe :src="dataUrl" frameborder="0" id="iframe"></iframe>
        </v-col>
      </v-row>
      <v-footer fixed height="50" class="d-flex justify-center">
        <v-btn-toggle>
          <v-btn @click="closePrintTotalsModal" color="primary">Sluit</v-btn>
          <v-btn color="primary" @click="print">Afdrukken</v-btn>
        </v-btn-toggle>
      </v-footer>
    </v-card>
  </v-dialog>
</template>
<script>
import { errorHandler, fetchPOST } from "../js/functions"
import { viewOrders,printOrders } from "../js/pdfController"
export default {
  name: "printOrdersModal",
  props: {
    printOrdersModal: Boolean,
    selectedOrders: Array,
  },
  data() {
    return {
      orders: [],
      buffer: "",
      dataUrl: "",
      iframeLoaded: false,
      pageSizes: [
        {value: "A4", text: "A4"},
        {value: "A5", text: "A5"},
      ],
      options: {
        pageSize: "A4",
        copies: 1,
        printer: ""
      },
      selectedStatus: 0,
      selectedCategories: [],
      selectedRoutes: [],
      allRoutes: false,
      allCategories: false,
      statuses: [
        { name: "Alles", value: null },
        { name: "In afwachting", value: 0 },
        { name: "In behandeling", value: 1 },
        { name: "Voltooid", value: 2 }
      ],
    }
  },
  methods: {
    getPrinterList() {
      window.ipcRenderer.send("getPrinterList", null)
      window.ipcRenderer.receive("getPrinterList", data => {
        this.printers = data
      })
    },
    selectStatus() {
      this.allRoutes = false
      this.selectedRoutes = []
    },
    selectAllRoutes() {
      this.allRoutes = !this.allRoutes
      this.selectedRoutes = this.allRoutes ? Array.from(this.routes) : []
      this.viewOrders()
    },
    selectRoute() {
      this.viewOrders()
    },
    iframeOnload() {
      let iframe = document.getElementById("iframe")
      iframe.removeEventListener("load", this.iframeOnload)
      if (
        iframe.contentDocument.readyState === "complete" &&
        this.iframeLoaded
      ) {
        this.printOrders()
      } else {
        var that = this
        setTimeout(function() {
          return that.iframeOnload(iframe)
        }, 1000)
      }
    },
    async print() {
      if (this.isElectron) {
        this.printOrders()
      } else {
      document
        .getElementById("iframe")
        .addEventListener("load", this.iframeOnload)
      await this.viewOrders()
      this.iframeLoaded = true
      }
    },
    async printOrders() {
      localStorage.setItem("printOrderOptions", JSON.stringify(this.options))
      if (this.isElectron) {
        let options = {
          printer: this.options.printer,
          paperSize: this.options.pageSize,  
          copies: this.options.copies,
          scale: "fit",
          silent: true
        }
        this.buffer = await printOrders(this.sortedOrders, this.options)
        window.ipcRenderer.send("printPdfBuffer", {
          buffer: this.buffer,
          options: options
        })
      } 
      else document.getElementById("iframe").contentWindow.print()
      setTimeout(async () => {
        try {
          let orders = this.sortedOrders.map(order => {
            return {
              orderNumber: order.orderNumber,
              clientNumber: order.clientNumber,
              status: order.status < 2 ? 2 : order.status
            }
          })
          await fetchPOST("ordersPrinted", {orders: orders})
          this.$store.commit("ordersPrinted", orders)
          let input = document.getElementById("input")
          input.focus()
        } catch (e) {
          errorHandler(e, "Orders niet geprint")
        }
      }, 1000)
    },
    closePrintTotalsModal() {
      URL.revokeObjectURL(this.dataUrl)
      this.$emit("close-print-orders-modal")
    },
    async viewOrders() {
      if (this.sortedOrders.length) {
        let data = await viewOrders(this.sortedOrders,this.options)
        this.dataUrl = URL.createObjectURL(data)
      }
    }
  },
  computed: {
    sortedOrders() {
      let orders = []
      let unsortedOrders = this.orders
      let sortedOrders = unsortedOrders.sort((a,b)=>{
        if(a.route<b.route)return -1
        if(a.route>b.route)return 1
        return 0
      })
      for (let order of sortedOrders) {
        if (
          (order.status == this.selectedStatus ||
            this.selectedStatus == null) &&
          (this.selectedRoutes.includes(order.route) ||
            this.selectedRoutes.length == 0)
        ) {
          orders.push(order)
        }
      }
      return orders
    },
    routes() {
      let routes = new Set()
      for (let order of this.sortedOrders) {
        if (order.status == this.selectedStatus || this.selectedStatus == null)
          routes.add(order.route)
      }
      return Array.from(routes).sort()
    },
    isElectron(){
      return this.$store.state.isElectron
    },
    printers(){
      return this.$store.state.printers
    }
  },
  async mounted() {
    if(this.selectedOrders!=null){
      this.orders =  this.selectedOrders
    }
    else {
      this.orders = this.$store.state.orders
    }
    if (JSON.parse(localStorage.getItem("printOrderOptions")) !== null) {
      let obj = JSON.parse(localStorage.getItem("printOrderOptions"))
      this.options = obj
    }
    await this.viewOrders()
  }
}
</script>
<style scoped>
div.settings {
  justify-content: space-evenly;
  font-size: 2em;
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 5px;
}
input[type="checkbox"] {
  height: 1em;
  width: 1em;
}
input,select {
  border:1px solid black;
}
div.settings label {
  text-align: right;
}
#iframe {
  height: 700px;
  width: 600px;
  margin: auto;
  display: block;
}
.hidden {
  width: 0;
  height: 0;
  overflow: hidden;
}
</style>