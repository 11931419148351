  <template>
  <div>
    <v-card class="pa-2">
      <v-row no-gutters>
        <v-col>
          <div class="header-wrapper">
            <div class="date-wrapper d-flex">
              <div>
              <div class="date-toggle">
                <v-btn
                  small
                  depressed
                  tile
                  :class="{ 'active-btn': allDates === false ? true : false }"
                  width="100%"
                  @click="changeDate(false)"
                  >Vandaag</v-btn
                >
                <v-btn
                  small
                  depressed
                  tile
                  :class="{ 'active-btn': allDates === true ? true : false }"
                  width="100%"
                  @click="changeDate(true)"
                  >Alles</v-btn
                >
                <v-btn
                  small
                  depressed
                  tile
                  :class="{ 'active-btn': allDates === -1 ? true : false }"
                  width="100%"
                  @click="changeDate(-1)"
                  >Datum</v-btn
                >
              </div>
              </div>
              <div class="date-wrapper d-flex flex-wrap align-center">
                <div class="search-date">
                  <date-picker
                    :dense="true"
                    :outlined="true"
                    :filled="true"
                    :value="fromDate"
                    label="Datum"
                    :dateFormatted="fromDateFormatted"
                    :disabled="allDates !== -1"
                    @changeDate="changeFromDate"
                  ></date-picker>
                </div>
                <div class="search-date">
                  <date-picker
                    :dense="true"
                    :outlined="true"
                    :filled="true"
                    :value="toDate"
                    label="t/m"
                    :dateFormatted="toDateFormatted"
                    :disabled="allDates !== -1"
                    @changeDate="changeToDate"
                  ></date-picker>
                </div>
                <div class="add-to-date d-flex justify-center align-self-center">
                  <v-btn class="mr-1" color="primary" x-small @click="addToDate(-1)"><h2>-1</h2></v-btn>
                  <v-btn color="primary" x-small @click="addToDate(1)"><h2>+1</h2></v-btn>
                </div>
              </div>
            </div>
            <div class="header-inner-wrapper">
              <search-client
                class="search-client"
                :searchString="searchString"
                @setSearchString="setSearchString"
                @get-results="getClientOrders"
                @clear-input="clearClient"
              ></search-client>
              <div class="search-order">
                <v-text-field
                  outlined
                  filled
                  type="number"
                  v-model="searchOrderNumber"
                  @keydown.enter="fetchOrder"
                  prepend-inner-icon="mdi-file-search"
                  label="Ordernummer"
                  clearable
                  hide-details
                ></v-text-field>
              </div>
              <div class="search-route">
                <v-select
                  outlined
                  filled
                  clearable
                  label="Route"
                  placeholder="Kies een route"
                  :items="activeRoutes"
                  v-model="selectedRoute"
                  @change="selectRoute"
                  @click:clear="clearRoute"
                  prepend-inner-icon="mdi-routes"
                  item-text="route"
                  :menu-props="{
                    closeOnClick: true,
                    closeOnContentClick: false,
                    disableKeys: true,
                    openOnClick: false,
                    maxHeight: 304
                  }"
                  hide-details
                >
                  <template v-slot:item="{ item }">
                    <option :value="item.route">
                      {{ item.route }}
                      [{{ item.length }}]
                    </option>
                  </template>
                </v-select>
              </div>
            </div>
            <div class="status-bar">
              <v-row no-gutters>
                <fieldset class="fieldset pa-4">
                  <legend class="legend">Status</legend>
                  <v-badge
                    overlap
                    offset-y="3px"
                    v-for="(status, index) in statuses"
                    :key="index"
                    :content="status.length > 0 ? status.length : '0'"
                  >
                    <v-chip
                      :close="selectedStatus == status.status"
                      :class="{
                        selectedStatus: selectedStatus == status.status
                      }"
                      @click="selectStatus(status.status)"
                      @click:close="selectStatus(-1)"
                      :color="status.color"
                      >{{ status.text }}</v-chip
                    >
                  </v-badge>
                </fieldset>
              </v-row>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-5">
        <v-col class="d-flex">
          <v-btn-toggle style="background:none;" class="d-flex flex-wrap">
            <v-btn color="secondary" @click="selectCategory(false)">
              Alles
            </v-btn>
            <v-btn
              color="secondary"
              v-for="category of $store.state.settings.categories"
              :key="category"
              @click="selectCategory(category)"
            >
              {{ category }}
            </v-btn>
          </v-btn-toggle>
          <div class="d-flex flex-wrap ml-4">
            <v-btn-toggle>
              <v-btn @click="printOrders()">
                <v-icon>mdi-printer</v-icon>
              </v-btn>
              <v-btn @click="openPrintTotalsModal()">
                <v-icon>mdi-file-document-multiple</v-icon>
              </v-btn>
              <v-btn
                color="secondary"
                :disabled="
                  Object.keys(this.selectedClient).length &&
                  this.selectedOrders.length
                    ? false
                    : true
                "
                @click="openInsertInvoiceModal(false)"
                >Factuur</v-btn
              >
              <v-btn @click="toggleAll()">
                <span v-if="expand">
                  <v-icon large>mdi-chevron-up</v-icon>
                </span>
                <span v-else>
                  <v-icon large>mdi-chevron-down</v-icon>
                </span>
              </v-btn>
            </v-btn-toggle>
          </div>
          <div class="ml-4">
            <v-checkbox
              v-model="showMeTheMoney"
              label="Toon totalen"
              @change="toggleShowMeTheMoney"
            ></v-checkbox>
          </div>
          <div class="ml-4">
            <v-btn-toggle>
            <v-btn @click="compareOrdersByDateModal=true">Niet besteld</v-btn>
            <v-btn @click="printOrders">Afdrukken</v-btn>
            <v-btn @click="emailOrders">Mailen</v-btn>
            </v-btn-toggle>
          </div>
        </v-col>
      </v-row>
    </v-card>
    <v-row>
      <v-col>
        <div
          v-if="showMeTheMoney"
          class="d-flex justify-space-around flex-wrap"
        >
          <span class="overview-totals"
            >Excl. : {{ toCurrency(netTotal) }}</span
          >
          <span class="overview-totals"
            >Incl. : {{ toCurrency(grossTotal) }}</span
          >
          <span class="overview-totals"
            >Te factureren : {{ toCurrency(openOrders) }}</span
          >
          <span class="overview-totals"
            >Totaal: {{ toDouble(totalWeight) }}kg</span
          >
        </div>
      </v-col>
    </v-row>
    <v-row class="my-0">
      <v-col cols="12" v-if="selectedCategory">
        <v-list class="columns" v-if="viewAggregate && allDates">
          <v-list-item v-for="(item, index) in totalItemsSold" :key="index">
            <v-row class="my-0 columns">
              <v-col class="borders">
                <div flat class="align-self-center text-center display-1">
                  {{ item._id }}
                </div>
              </v-col>
              <v-col class="borders">
                <div flat class="align-self-center text-center display-1">
                  {{ toDouble(item.totalAmount) }}{{ item.unit }}
                </div>
              </v-col>
            </v-row>
          </v-list-item>
        </v-list>
        <v-list class="columns" v-if="viewAggregate && !allDates">
          <v-list-item
            class="totals"
            v-for="(item, index) in categories"
            :key="index"
          >
            <v-row class="my-0">
              <v-col class="borders">
                <div class="align-self-center text-center display-1">
                  {{ index }}
                </div>
              </v-col>
              <v-col class="borders">
                <div flat class="align-self-center text-center display-1">
                  {{ toDouble(item.amount) }}{{ item.unit }}
                </div>
              </v-col>
              <v-col
                class="borders"
                v-if="selectedStatus < 2 && selectedRoute != ''"
              >
                <v-select
                  label="Werknemer"
                  prepend-icon="mdi-worker"
                  :items="employees"
                  item-value="_id"
                  item-text="name"
                  v-model="item.assigned"
                  @change="pickItem(item)"
                ></v-select>
                <a @click="pickItem(item)">
                  <v-icon large>mdi-autorenew</v-icon>
                </a>
                <v-btn @click="completeItem(item)">Voltooid</v-btn>
              </v-col>
            </v-row>
          </v-list-item>
        </v-list>
        <v-row class="my-0" v-if="!viewAggregate && !allDates">
          <v-col cols="12" class="d-flex flex-wrap">
            <div v-for="(children, property) in categories" :key="property">
              <v-card class="group" v-if="children.items.length">
                <table>
                  <tr>
                    <th>{{ property }}</th>
                  </tr>
                  <tr v-for="(item, index) of children.items" :key="item._id">
                    <td>
                      <a @click="openEditItemModal(item, index)">{{
                        item.client
                      }}</a>
                    </td>
                    <td>
                      <a @click="openEditItemModal(item, index)"
                        >{{ item.amount }}{{ item.unit }}</a
                      >
                    </td>
                    <td>
                      <v-icon
                        @click="incItemStatus(item.order_id, item)"
                        :color="itemStatuses[item.status].color"
                        >{{ itemStatuses[item.status].icon }}</v-icon
                      >
                    </td>
                  </tr>
                  <tr>
                    <td>Totaal:</td>
                    <td></td>
                    <td>{{ toDouble(children.total) }}</td>
                  </tr>
                </table>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="!selectedCategory && !viewAggregate">
      <v-col class="d-flex flex-wrap">
        <h2 v-if="orders.length == 0">Er zijn geen orders</h2>
        <div
          v-for="order in orders"
          :key="order._id"
          :id="order._id"
          :name="order.name"
        >
          <v-card class="order" v-bind:class="{ priority: order.priority }">
            <table>
              <tr>
                <td class="statusBar">
                  <v-icon @click="printOrderLabel(order)"
                    >mdi-text-box-plus</v-icon
                  >
                </td>
                <th>
                  <a @click="openOrderModal(order)">{{ order.name }}</a>
                </th>
                <td>
                  <v-icon
                    :color="orderStatuses[order.status].color"
                    @click="incOrderStatus(order)"
                  >
                    {{ orderStatuses[order.status].icon }}</v-icon
                  >
                </td>
              </tr>
              <tr
                v-for="item in order.items"
                :key="item._id"
                v-show="item.status < 2 || order.expand"
              >
                <td class="amount">
                  <a v-on:click="openEditItemModal(item, order)">
                    {{ item.amount }}
                    <span class="unit">{{ item.unit }}</span>
                  </a>
                </td>
                <td class="product">
                  <a v-on:click="openEditItemModal(item, order)">{{
                    item.name
                  }}</a>
                </td>
                <td>
                  <v-icon
                    @click="incItemStatus(order._id, item)"
                    :color="itemStatuses[item.status].color"
                    >{{ itemStatuses[item.status].icon }}</v-icon
                  >
                </td>
              </tr>
              <tr v-if="order.expand">
                <td colspan="3" class="totals">
                  {{ `${order.items.length}` }}
                </td>
              </tr>
              <tr>
                <td>
                  <a v-on:click="toggleView(order)">
                    <v-icon v-if="order.expand" large>mdi-chevron-up</v-icon>
                    <v-icon v-else large>mdi-chevron-down</v-icon>
                  </a>
                </td>
                <td>
                  <v-icon v-show="order.notes">mdi-message-text</v-icon>
                </td>
                <td>
                  <a
                    v-show="order.expand && order.status > 0"
                    @click="openPrintOrderModal(order)"
                  >
                    <v-icon large v-if="order.printed" color="light-green"
                      >mdi-printer-check</v-icon
                    >
                    <v-icon large v-else color="red">mdi-printer</v-icon>
                  </a>
                </td>
              </tr>
            </table>
          </v-card>
        </div>
      </v-col>
    </v-row>
    <v-row class="my-0" v-if="!selectedCategory && viewAggregate" no-gutters>
      <v-col>
        <v-data-table
          v-if="allDates"
          v-model="selectedOrders"
          :headers="orderHeaders"
          :items="orders"
          :single-select="singleSelect"
          :options.sync="options"
          @update:options="updateOptions"
          :server-items-length="totalOrders"
          item-key="_id"
          :footer-props="{ 'items-per-page-options': [-1, 10, 25, 50, 100] }"
          show-select
          class="elevation-1"
          locale="nl"
          selectable-key="selectable"
        >
          <template v-slot:[`item.date`]="{ item }">{{
            new Date(item.date).toLocaleDateString("nl-NL")
          }}</template>
          <template v-slot:[`item.invoiceNumber`]="{ item }">
            <a v-if="item.invoiceNumber">{{ item.invoiceNumber }}</a>
            <a v-else @click="openInsertInvoiceModal(true, item)"
              ><v-btn small>Factuur</v-btn></a
            >
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <a @click="openOrderModal(item)">{{ item.name }}</a>
          </template>
          <template v-slot:[`item.route`]="{ item }">
            <v-select
              :items="routes"
              v-model="item.route"
              dense
              @change="updateOrder(item, true)"
            ></v-select>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon @click="openPreviewOrderModal(item)">mdi-eye</v-icon>
            <v-icon @click="downloadOrder(item)">mdi-download</v-icon>
            <v-icon @click="openOrderPaymentModal(item)"
              >mdi-currency-eur</v-icon
            >
          </template>
          <template v-slot:[`item.printed`]="{ item }">
            <a @click="openPrintOrderModal(item)">
              <v-icon large v-if="item.printed" color="light-green"
                >mdi-printer-check</v-icon
              >
              <v-icon large v-else color="red">mdi-printer</v-icon>
            </a>
          </template>
          <template v-slot:[`item.emailed`]="{ item }">
            <a @click="openEmailOrderModal(item)">
              <v-icon large v-if="item.emailed" color="light-green"
                >mdi-email-check</v-icon
              >
              <v-icon large v-else color="red">mdi-email</v-icon>
            </a>
          </template>
          <template v-slot:[`item.maps`]="{ item }">
            <a @click="openGoogleMaps(item)">
              <v-icon large>mdi-google-maps</v-icon>
            </a>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <div>
              <v-chip
                label
                @click="incOrderStatus(item)"
                :color="orderStatuses[item.status].color"
                >{{ orderStatuses[item.status].text }}</v-chip
              >
            </div>
          </template>
          <template v-slot:[`item.grossTotal`]="{ item }">
            <div>{{ toCurrency(item.grossTotal) }}</div>
          </template>
          <template v-slot:[`item.debit`]="{ item }">
            <div>{{ toCurrency(item.debit) }}</div>
          </template>
          <template v-slot:[`item.totalCrates`]="{ item }">
            <div>{{ item.totalCrates }}</div>
          </template>
          <template v-slot:[`item.returnedCrates`]="{ item }">
            <div><v-text-field type="number" v-model.number="item.returnedCrates"></v-text-field></div>
          </template>
          <template v-slot:[`item.client.postalcode`]="{ item }">
            {{ item.client.postalcode }}
          </template>
          <template v-slot:[`item.client.invoiceFrequency`]="{ item }">
            <v-select 
              :items="['Dagelijks', 'Wekelijks', 'Maandelijks']"
              v-model="item.client.invoiceFrequency"
              dense
              @change="updateInvoiceFrequency(item)"            
            ></v-select>
          </template>
        </v-data-table>
        <v-data-table
          v-else
          v-model="selectedOrders"
          :headers="orderHeaders"
          :items="orders"
          :single-select="singleSelect"
          item-key="_id"
          :options.sync="options"
          :footer-props="{ 'items-per-page-options': [-1, 10, 25, 50, 100] }"
          show-select
          :expanded.sync="expanded"
          class="elevation-1"
          locale="nl"
          selectable-key="selectable"
        >
          <template v-slot:[`item.date`]="{ item }">{{
            new Date(item.date).toLocaleDateString("nl-NL")
          }}</template>
          <template v-slot:[`item.invoiceNumber`]="{ item }">
            <a v-if="item.invoiceNumber">{{ item.invoiceNumber }}</a>
            <a v-else @click="openInsertInvoiceModal(true, item)"
              ><v-btn small>Factuur</v-btn></a
            >
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <a @click="openOrderModal(item)">{{ item.name }}</a>
          </template>
          <template v-slot:[`item.route`]="{ item }">
            <v-select
              :items="routes"
              v-model="item.route"
              dense
              @change="updateOrder(item, true)"
            ></v-select>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon @click="openPreviewOrderModal(item)">mdi-eye</v-icon>
            <v-icon @click="downloadOrder(item)">mdi-download</v-icon>
            <v-icon @click="openOrderPaymentModal(item)"
              >mdi-currency-eur</v-icon
            >
          </template>
          <template v-slot:[`item.printed`]="{ item }">
            <a @click="openPrintOrderModal(item)">
              <v-icon large v-if="item.printed" color="light-green"
                >mdi-printer-check</v-icon
              >
              <v-icon large v-else color="red">mdi-printer</v-icon>
            </a>
          </template>
          <template v-slot:[`item.emailed`]="{ item }">
            <a @click="openEmailOrderModal(item)">
              <v-icon large v-if="item.emailed" color="light-green"
                >mdi-email-check</v-icon
              >
              <v-icon large v-else color="red">mdi-email</v-icon>
            </a>
          </template>
          <template v-slot:[`item.maps`]="{ item }">
            <a @click="openGoogleMaps(item)">
              <v-icon large>mdi-google-maps</v-icon>
            </a>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <div>
              <v-chip
                label
                @click="incOrderStatus(item)"
                :color="orderStatuses[item.status].color"
                >{{ orderStatuses[item.status].text }}</v-chip
              >
            </div>
          </template>
          <template v-slot:[`item.grossTotal`]="{ item }">
            <div>{{ toCurrency(item.grossTotal) }}</div>
          </template>
          <template v-slot:[`item.debit`]="{ item }">
            <div>{{ toCurrency(item.debit) }}</div>
          </template>
          <template v-slot:[`item.totalCrates`]="{ item }">
            <div>{{ item.totalCrates }}</div>
          </template>
          <template v-slot:[`item.returnedCrates`]="{ item }">
            <div><v-text-field type="number" v-model.number="item.returnedCrates" @change="updateOrder(item)" @focus="$event.target.select()"></v-text-field></div>
          </template>
          <template v-slot:[`item.client.postalcode`]="{ item }">
            {{ item.client.postalcode }}
          </template>
          <template v-slot:[`item.client.invoiceFrequency`]="{ item }">
            <v-select 
              :items="['Dagelijks', 'Wekelijks', 'Maandelijks']"
              v-model="item.client.invoiceFrequency"
              dense
              @change="updateInvoiceFrequency(item)"            
            ></v-select>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <edit-item-modal
      v-if="editItemModal"
      :order="order"
      :client="client"
      :editItemModal="editItemModal"
      :item="JSON.parse(JSON.stringify(thisItem))"
      @update-order-item="updateOrderItem"
      @delete-order-item="deleteOrderItem"
      @close-item="closeEditItemModal"
    ></edit-item-modal>
    <order-modal
      v-if="orderModal"
      :order="JSON.parse(JSON.stringify(order))"
      :orderModal="orderModal"
      @update-order="updateOrder"
      @delete-order="openPromptModal"
      @update-order-item="updateOrderItem"
      @open-print-order-modal="openPrintOrderModal"
      @open-email-order-modal="openEmailOrderModal"
      @close-order-modal="closeOrderModal"
    ></order-modal>
    <print-order-modal
      v-if="printOrderModal"
      :printOrderModal="printOrderModal"
      :order="order"
      @close-print-order-modal="closePrintOrderModal"
    ></print-order-modal>
    <print-orders-modal
      v-if="printOrdersModal"
      :printOrdersModal="printOrdersModal"
      :orders="orders"
      :selectedOrders="selectedOrders"
      @close-print-orders-modal="closePrintOrdersModal"
    ></print-orders-modal>
    <email-order-modal
      v-if="emailOrderModal"
      :emailOrderModal="emailOrderModal"
      :order="order"
      @close-email-order-modal="closeEmailOrderModal"
    ></email-order-modal>
    <insert-invoice-modal
      v-if="insertInvoiceModal"
      :insertInvoiceModal="insertInvoiceModal"
      :order="order"
      :selectedOrders="selectedOrders"
      :client="client"
      :singleOrder="singleOrder"
      @add-invoice-payments="openInvoicePaymentModal"
      @close-insert-invoice-modal="closeInsertInvoiceModal"
    ></insert-invoice-modal>
    <order-payment-modal
      v-if="orderPaymentModal"
      :orderPaymentModal="orderPaymentModal"
      :order="order"
      @insert-payments="insertOrderPayments"
      @close-payment-modal="closeOrderPaymentModal"
    ></order-payment-modal>
    <invoice-payment-modal
      v-if="invoicePaymentModal"
      :invoicePaymentModal="invoicePaymentModal"
      :invoice="invoice"
      @insert-payments="insertInvoicePayments"
      @close-payment-modal="closeInvoicePaymentModal"
    ></invoice-payment-modal>
    <print-totals-modal
      v-if="printTotalsModal"
      :printTotalsModal="printTotalsModal"
      :route="selectedRoute"
      @close-print-totals-modal="closePrintTotalsModal"
    ></print-totals-modal>
    <prompt-modal
      v-if="promptModal"
      @confirm="deleteOrder"
      @cancel="promptModal = false"
      :data="order"
      :promptModal="promptModal"
      promptTitle="Verwijder order"
      promptText="Weet u zeker dat u deze order wilt verwijderen?"
    >
      <table>
        <tr>
          <th>Ordernr:</th>
          <td>{{ order.orderNumber }}</td>
        </tr>
        <tr>
          <th>Date:</th>
          <td>{{ toDateString(order.date) }}</td>
        </tr>
        <tr>
          <th>Naam:</th>
          <td>{{ order.name }}</td>
        </tr>
        <tr>
          <th>Bedrag:</th>
          <td>{{ toCurrency(order.grossTotal) }}</td>
        </tr>
      </table>
    </prompt-modal>
    <preview-order-modal
      v-if="previewOrderModal"
      :previewOrderModal="previewOrderModal"
      :order="order"
      @close-preview-order-modal="closePreviewOrderModal"
    >
    </preview-order-modal>
    <compare-orders-by-date-modal
    v-if="compareOrdersByDateModal"
    :compareOrdersByDateModal="compareOrdersByDateModal"
    :orders="orders"
    @close-compare-orders-by-date-modal="compareOrdersByDateModal=false"
    >
    </compare-orders-by-date-modal>
    <email-orders-modal
    v-if="emailOrdersModal"
    :emailOrdersModal="emailOrdersModal"
    :selectedOrders="selectedOrders"
    >
    </email-orders-modal>
  </div>
</template>
<script>
import Big from "big.js"
import searchClient from "../components/searchClient"
import editItemModal from "../components/editItemModal"
import orderPaymentModal from "../components/orderPaymentModal"
import invoicePaymentModal from "../components/invoicePaymentModal"
import orderModal from "../components/orderModal"
import printOrderModal from "../components/printOrderModal"
import printOrdersModal from "../components/printOrdersModal"
import emailOrderModal from "../components/emailOrderModal"
import insertInvoiceModal from "../components/insertInvoiceModal"
import promptModal from "../components/promptModal"
import previewOrderModal from "../components/previewOrderModal"
import printTotalsModal from "../components/printTotalsModal"
import datePicker from "../components/datePicker"
import compareOrdersByDateModal from '@/components/compareOrdersByDateModal.vue'
import {
  errorHandler,
  fetchGET,
  fetchPOST,
  successHandler,
  toDateString,
  toCurrency,
  toDouble
} from "../js/functions.js"
import { downloadOrder, printOrderLabel } from "../js/pdfController"
import emailOrdersModal from '@/components/emailOrdersModal.vue'
/* eslint-env es6 */
fetchPOST
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "orders",
  data() {
    return {
      mounted: false,
      selectMinStatus: false,
      categories2: [],
      allDates: false,
      promptModal: false,
      previewOrderModal: false,
      client: {},
      fromDate: new Date(Date.now()).toISOString().split("T")[0],
      toDate: new Date(Date.now()).toISOString().split("T")[0],
      fromDateFormatted: this.formatDate(
        new Date().toISOString().split("T")[0]
      ),
      toDateFormatted: this.formatDate(new Date().toISOString().split("T")[0]),
      editItemModal: false,
      orderModal: false,
      printOrderModal: false,
      printOrdersModal: false,
      emailOrderModal: false,
      insertInvoiceModal: false,
      printTotalsModal: false,
      dataUrl: "",
      itemHeaders: [
        { text: "Status", value: "status" },
        { text: "Naam", value: "name" },
        { text: "Aantal", value: "amount" },
        { text: "Inhoud", value: "unitAmount" },
        { text: "Prijs", value: "price" },
        { text: "Subtotaal", value: "netSubtotal" }
      ],
      itemStatuses: [
        {
          status: 0,
          icon: "mdi-checkbox-blank-circle",
          color: "red",
          text: "In afwachting"
        },
        {
          status: 1,
          icon: "mdi-checkbox-blank-circle",
          color: "orange",
          text: "In behandeling"
        },
        {
          status: 2,
          icon: "mdi-checkbox-blank-circle",
          color: "light-green",
          text: "Voltooid"
        },
        {
          status: 3,
          icon: "mdi-checkbox-marked-circle",
          color: "orange",
          text: "Geselecteerd"
        },
        {
          status: 4,
          icon: "mdi-checkbox-marked-circle",
          color: "light-green",
          text: "Label afgedrukt"
        }
      ],
      orderStatuses: [
        {
          status: 0,
          icon: "mdi-checkbox-blank-circle",
          color: "red",
          text: "Nieuw",
          length: 0
        },
        {
          status: 1,
          icon: "mdi-checkbox-blank-circle",
          color: "orange",
          text: "In behandeling",
          length: 0
        },
        {
          status: 2,
          icon: "mdi-checkbox-blank-circle",
          color: "light-green",
          text: "Voltooid",
          length: 0
        },
        {
          status: 3,
          icon: "mdi-checkbox-marked-circle",
          color: "black",
          text: "Gefactureerd",
          length: 0
        }
      ],
      orderPaymentModal: false,
      invoicePaymentModal: false,
      singleSelect: false,
      selectedOrders: [],
      singleOrder: false,
      options: {
        itemsPerPage: 50,
        page: 1,
        sortBy: ["orderNumber"],
        sortDesc: [true]
      },
      order: {},
      ordersLength: 0,
      orderNetTotal: 0,
      orderGrossTotal: 0,
      orderDebit: 0,
      openOrdersGrossTotal: 0,
      invoice: {},
      thisItem: { name: "", amount: 0 },
      itemCopy: { name: "", amount: 0 },
      searchResults: false,
      tv: false,
      expand: false,
      expanded: [],
      selectedStatus: -1,
      searchOrderNumber: "",
      searchClient: false,
      searchString: "",
      selectedRoute: "",
      selectedCategory: false,
      viewAggregate: true,
      tableView: true,
      columnView: false,
      cardView: false,
      printType: "",
      showMeTheMoney: false,
      compareOrdersByDateModal: false,
      emailOrdersModal: false
    }
  },
  methods: {
    toDouble(val) {
      return toDouble(val)
    },
    async changeDate(allDates) {
      this.allDates = allDates === undefined ? this.allDates : allDates
      if(allDates===false){
        this.fromDate = new Date(Date.now()).toISOString().split("T")[0],
        this.fromDateFormatted = this.formatDate(this.fromDate)
        this.toDate = this.fromDate
        this.toDateFormatted = this.fromDateFormatted
      }
      this.fetchOrders()
    },
    changeFromDate(date) {
      this.fromDate = date
      this.fromDateFormatted = this.formatDate(date)
      if (this.fromDate > this.toDate) {
        this.toDate = this.fromDate
        this.toDateFormatted = this.fromDateFormatted
      }
      this.changeDate()
    },
    changeToDate(date) {
      this.toDate = date
      this.toDateFormatted = this.formatDate(date)
      if (this.toDate < this.fromDate) {
        this.fromDate = this.toDate
        this.fromDateFormatted = this.toDateFormatted
      }
      this.changeDate()
    },
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split("-")
      return `${day}-${month}-${year}`
    },
    parseDate(date) {
      if (!date) return null
      const [day, month, year] = date.split("-")
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`
    },
    updateOptions() {
      if (this.allDates !== false) this.fetchOrders()
    },
    closeOrderPaymentModal() {
      this.order = {}
      this.orderPaymentModal = false
      this.invoicePaymentModal = false
    },
    closeInvoicePaymentModal() {
      this.invoice = {}
      this.invoicePaymentModal = false
    },
    closePrintOrderModal() {
      this.order = {}
      this.printOrderModal = false
    },
    closePrintOrdersModal() {
      this.printOrdersModal = false
    },
    closeEmailOrderModal() {
      this.order = {}
      this.emailOrderModal = false
    },
    closeInsertInvoiceModal() {
      this.order = {}
      this.selectedOrders = []
      this.insertInvoiceModal = false
    },
    openPromptModal(order) {
      this.order = order
      this.promptModal = true
    },
    insertOrderPayments(payments) {
      this.order.payments = payments
      let totalPaid = payments.reduce(
        (acc, cur) => (acc * 100 + cur.amount * 100) / 100,
        0
      )
      this.order.credit = totalPaid
      this.order.debit = (this.order.grossTotal * 100 - totalPaid * 100) / 100
      this.order.status = this.order.debit == 0 ? 2 : 1
      this.updateOrder(this.order)
      this.orderPaymentModal = false
    },
    insertInvoicePayments(payments) {
      this.invoice.payments = payments
      let totalPaid = payments.reduce(
        (acc, cur) => (acc * 100 + parseFloat(cur.amount) * 100) / 100,
        0
      )
      this.invoice.credit = totalPaid
      this.invoice.debit =
        (this.invoice.grossTotal * 100 - totalPaid * 100) / 100
      this.invoice.status =
        this.invoice.debit < 0.01 && this.invoice.debit > -0.01 ? 2 : 1 //TODO: check if this is correct
      this.updateInvoice(this.invoice)
      this.invoicePaymentModal = false
    },
    async updateInvoice(invoice) {
      try {
        let res = await fetchPOST("updateInvoice", invoice)
        if (res.result.nModified == 0) throw "updateInvoice"
        this.$store.commit("updateInvoice", invoice)
        successHandler("Factuur gewijzigd")
      } catch (e) {
        errorHandler(e, "Factuur niet gewijzigd")
      }
    },
    pickItem(item) {
      let data = {
        item: item,
        route: this.selectedRoute,
        date: this.date
      }
      this.$store.commit("assignItem", data)
      this.$store.state.wss.send(
        JSON.stringify({ event: "pickItem", data: data })
      )
    },
    pickOrder(order) {
      if (order.assigned && order.status == 0) {
        order.status = 1
        this.$store.commit("incOrderStatus", order)
      }
      this.$store.commit("assignOrder", order)
      this.$store.state.wss.send(
        JSON.stringify({ event: "pickOrder", data: order })
      )
    },
    fetchOrder() {
      this.$store.dispatch("fetchGET", {
        type: "fetchOrders",
        data: {
          orderNumber: this.searchOrderNumber
        }
      })
    },
    async fetchOrders() {
      let data = {
          clientNumber: this.selectedClient.clientNumber,
          category: this.selectedCategory,
          route: this.selectedRoute,
          fromDate: this.fromDate,
          toDate: this.toDate,
          allDates: this.allDates,
          status: this.selectedStatus,
          options: this.options
        }
      console.log("fetchOrders")
      this.categories2 = []
      this.categories2.length = 0
      try {
        let event =
          this.allDates && this.viewAggregate && this.selectedCategory
            ? "totalItemsSold"
            : "fetchOrders"
        let response = await this.$store.dispatch("fetchGET", {
          type: event,
          data: data
        })
        this.ordersLength = response[0] && response[0].length && response[0].length.length
          ? response[0].length[0].length
          : 0
        if (this.allDates && event == "fetchOrders") {
          let minStatus = 0
          let foundStatus = false
          for (let status of this.orderStatuses) {
            status.length = 0
            if (response[0].statuses && response[0].statuses.length) {
              let index = response[0].statuses.findIndex(
                i => i._id == status.status
              )
              if (index > -1) {
                if (!foundStatus && this.selectMinStatus) {
                  minStatus = status.status
                  foundStatus = true
                  if (minStatus < this.selectedStatus)
                    this.selectStatus(minStatus)
                }
                status.length = response[0].statuses[index].length
              }
            }
          }
          console.log(response)
          this.orderNetTotal = response[0].totals[0]
            ? response[0].totals[0].netTotal
            : 0
          this.orderGrossTotal = response[0].totals[0]
            ? response[0].totals[0].grossTotal
            : 0
          this.orderDebit = response[0].totals[0]
            ? response[0].totals[0].debit
            : 0
          this.openOrdersGrossTotal = response[0].openOrdersGrossTotal[0]
            ? response[0].openOrdersGrossTotal[0].grossTotal
            : 0
        }
      } catch (e) {
        errorHandler(e, "Kan totalen niet ophalen")
      }
    },
    downloadOrder(order) {
      downloadOrder(order, { includePrices: order.client.includePrices })
    },
    openPreviewOrderModal(order) {
      this.order = order
      this.previewOrderModal = true
    },
    closePreviewOrderModal() {
      this.order = {}
      this.previewOrderModal = false
    },
    setSearchString(val) {
      this.searchString = val
    },
    // getResults(results) {
    //   this.searchResults = results
    // },
    async getClientOrders(val) {
      try {
        this.selectMinStatus = true
        this.selectedOrders = []
        this.allDates = this.allDates === false ? true : this.allDates
        this.selectedStatus = 2
        this.selectedClient.clientNumber = val.clientNumber
        this.fetchOrders()
        this.$store.commit("selectClient", val)
      } catch (e) {
        console.error(e)
      }
    },
    clearClient() {
      this.$store.commit("selectClient", {})
      this.selectMinStatus = false
      this.searchResults = false
      this.searchString = ""
      this.allDates = this.allDates === true ? false : this.allDates
      this.selectedStatus = -1
      this.selectedClient.clientNumber = null
      this.fromDate = new Date().toISOString().split("T")[0]
      this.fromDateFormatted = this.formatDate(this.fromDate)
      this.toDate = new Date().toISOString().split("T")[0]
      this.toDateFormatted = this.formatDate(this.toDate)
      this.allDates = false
      this.fetchOrders()
    },
    selectStatus(val) {
      this.selectedStatus = val
      this.selectMinStatus = false
      this.options.page = 1
      if (this.allDates) this.fetchOrders()
    },
    selectRoute() {
      if (
        this.allDates &&
        this.viewAggregate &&
        (this.selectedCategory || this.selectedRoute)
      ) {
        let data = {
          clientNumber: this.selectedClient.clientNumber,
          category: this.selectedCategory,
          route: this.selectedRoute,
          fromDate: this.fromDate,
          toDate: this.toDate,
          allDates: this.allDates,
          status: this.selectedStatus,
          options: this.options
        }
        this.$store.dispatch("fetchGET", { type: "totalItemsSold", data: data })
      }
    },
    clearRoute() {
      this.categories2.length = 0
      this.selectedRoute = "" 
      this.fetchOrders()
    },
    selectCategory(cat) {
      this.categories2 = []
      this.categories2.length = 0
      if (this.selectedCategory === cat && !this.allDates)
        this.viewAggregate = !this.viewAggregate
      this.selectedCategory = cat
      if (this.allDates && this.selectedCategory) {
        let data = {
          category: cat,
          clientNumber: this.selectedClient.clientNumber,
          route: this.selectedRoute,
          fromDate: this.fromDate,
          toDate: this.toDate,
          allDates: this.allDates
        }
        this.$store.dispatch("fetchGET", { type: "totalItemsSold", data: data })
      }
    },
    openOrderModal(order) {
      this.order = order
      this.orderModal = true
    },
    closeOrderModal() {
      this.order = {}
      this.orderModal = false
    },
    itemsOutOfStock(item) {
      try {
        for (let orderNumber of item.orders) {
          this.updateOrder(orderNumber)
        }
      } catch (e) {
        errorHandler(e, "Artikelen niet aangepast")
      }
    },
    itemOutOfStock(itemNumber) {
      try {
        console.log(itemNumber)
      } catch (e) {
        errorHandler(e, "Artikel niet aangepast")
      }
    },
    async updateOrderItem(item, oldVal, outOfStock) {
      try {
        console.log(item)
        if (outOfStock) {
          item.amount = 0
          item.status = 2
        }
        let amount = Big(item.amount)
        let price = Big(item.price)
        let unitAmount = Big(item.unitAmount)
        let packages = Big()
        let crates = item.crates
        let totalWeight = Big()
        let netSubtotal = Big()
        let totalVAT = Big()
        let grossSubtotal = Big()
        let itemVAT = Big(item.VAT).div(100)
        packages =
          item.unit == "kg"
            ? amount.div(item.packageAmount).round(undefined, 3)
            : amount
        crates = item.packaging == "Crate" && (item.unitAmount == 10 || item.unitAmount == '10')? amount.toNumber() : 0
        totalWeight =
          item.unit == "kg" ? amount : amount.times(item.unitAmount).round(2)
        netSubtotal =
          item.unit == "kg"
            ? amount.times(price).round(2)
            : amount
                .times(price)
                .times(unitAmount)
                .round(2)
        if (this.selectedClient.includeVAT)
          netSubtotal = netSubtotal.div(
            Big(item.VAT)
              .plus(100)
              .div(100)
          )
        totalVAT = netSubtotal.times(itemVAT)
        grossSubtotal = netSubtotal.plus(totalVAT)
        item.packages = packages.toNumber()
        item.crates = crates
        item.totalWeight = totalWeight.toNumber()
        item.netSubtotal = netSubtotal.toNumber()
        item.totalVAT = totalVAT.toNumber()
        item.grossSubtotal = grossSubtotal.toNumber()
        item.totalCost =
          item.unit == "kg"
            ? item.amount * item.cost
            : item.amount * item.unitAmount * item.cost
        item.totalProfit = item.netSubtotal - item.totalCost
        let order = this.findOrder(item.order_id)
        let index = order.items.findIndex(i => i._id == item._id)
        if (index > -1) {
          Object.assign(order.items[index], item)
        }
        let orderVAT
        if (order.client.export) {
          orderVAT = [{ name: "Geen", amount: 0, total: this.orderNetTotal }]
        } else {
          orderVAT = JSON.parse(JSON.stringify(this.$store.state.settings.VAT))
          for (let VAT of orderVAT) {
            VAT.total = order.items
              .filter(item => VAT.amount === item.VAT)
              .reduce((acc, cur) => {
                if (cur.VAT == 0)
                  return (acc * 100 + parseFloat(cur.netSubtotal) * 100) / 100
                else return (acc * 100 + parseFloat(cur.totalVAT) * 100) / 100
              }, 0)
          }
        }
        let VAT = orderVAT.filter(VAT => parseFloat(VAT.total) != 0)
        order.VAT = VAT
        let payments = order.payments
        let totalPaid = payments.reduce((acc, cur) => acc + cur.amount, 0)
        let totalWeight2 = order.items.reduce(
          (acc, cur) => acc + cur.totalWeight,
          0
        )
        order.netTotal = order.items.reduce(
          (acc, cur) => (acc * 100 + parseFloat(cur.netSubtotal) * 100) / 100,
          0
        )
        order.totalVAT = order.items.reduce(
          (acc, cur) => (acc * 100 + parseFloat(cur.totalVAT) * 100) / 100,
          0
        )

        order.grossTotal = (order.netTotal * 100 + order.totalVAT * 100) / 100
        order.totalWeight = totalWeight2
        order.credit = totalPaid
        order.debit = order.grossTotal - totalPaid
        order.totalPackages = order.items.reduce(
          (acc, cur) => (acc * 100 + parseFloat(cur.packages) * 100) / 100,
          0
        )
        let res = await fetchPOST("updateOrderItem", {
          order: order,
          item: item
        })
        console.log(res)
        if (res.result.nModified == 0) throw "updateOrderItem"
        this.$store.commit("updateOrderItem", { order: order, item: item })
        // if (!outOfStock) this.orderStatus(this.order)
        successHandler("Artikel gewijzigd")
        this.closeEditItemModal()
      } catch (e) {
        errorHandler(e, "Artikel niet gewijzigd")
      }
    },
    async deleteOrderItem(ids) {
      try {
        let order = this.findOrder(ids.order_id)
        let index = order.items.findIndex(i => i._id == ids.item_id)
        if (index > -1) {
          await this.updateOrderItem(order.items[index], 0, true)
          order.items.splice(index, 1)
        }
      } catch (e) {
        errorHandler(e, "Artikel niet verwijderd")
      }
    },
    async updateOrder(order, ignore) {
      try {
        this.$store.commit("updateOrder", order)
        console.log(ignore)
        console.log(order.returnedCrates)
        // if (!ignore) this.orderStatus(order)
        let res = await fetchPOST("updateOrder", order)
        if (res.result.nModified == 0) throw "updateOrder"
        successHandler("Order gewijzigd")
      } catch (e) {
        errorHandler(e, "Order niet gewijzigd")
      }
    },
    async deleteOrder() {
      try {
        let _id = this.order._id
        let res = await fetchPOST("deleteOrder", { _id: _id })
        if (res.result.nModified == 0) throw "deleteOrder"
        this.$store.commit("deleteOrder", _id)
        successHandler("Order verwijderd")
        this.order = {}
        this.promptModal = false
        this.closeOrderModal()
      } catch (e) {
        errorHandler(e, "Order niet verwijderd")
      }
    },
    openEditItemModal(item, order) {
      if (this.selectedCategory) {
        //TODO: fix this
        this.order = this.findOrder(item.order_id)
      } else {
        item.client = order.name
        item.order_id = order._id
        this.order = order
        this.client = order.client
      }
      this.thisItem = item
      this.itemCopy = JSON.parse(JSON.stringify(item))
      this.editItemModal = true
    },
    closeEditItemModal() {
      this.order = {}
      this.thisItem = {}
      this.editItemModal = false
    },
    printOrders() {
      this.printOrdersModal = true
    },
    openPrintOrderModal(order) {
      this.order = order
      this.printOrderModal = true
    },
    openEmailOrderModal(order) {
      this.order = order
      this.emailOrderModal = true
    },
    async openInsertInvoiceModal(singleOrder, order) {
      if (singleOrder) {
        this.singleOrder = true
        this.$store.commit("selectClient", order.client)
        this.order = order
      } else this.singleOrder = false
      this.printType = "newInvoice"
      this.insertInvoiceModal = true
    },
    async findClient(clientNumber) {
      let client = await fetchGET("findClient", clientNumber)
      return client
    },
    openPrintTotalsModal() {
      this.printTotalsModal = true
    },
    closePrintTotalsModal() {
      this.printTotalsModal = false
    },
    toggleAll() {
      for (let order of this.orders) {
        order.expand = this.expand ? false : true
      }
      this.expand = !this.expand
    },
    toggleCollapse(index, item) {
      item.expand = !item.expand
    },
    toggleView(order) {
      order.expand = !order.expand
    },
    async incItemStatus(order_id, item) {
      try {
        item.order_id = order_id
        if (item.status < 4) item.status++
        else item.status = 3
        let res = await fetchPOST("incItemStatus", item)
        if (res.result.nModified == 0) throw "incItemStatus"
        this.$store.commit("incItemStatus", item)
        // let order = this.findOrder(order_id)
        // this.orderStatus(order)
      } catch (e) {
        errorHandler(e, "Status niet gewijzigd")
      }
    },
    async incOrderStatus(order) {
      try {
        if (order.status < 2) order.status++
        let res = await fetchPOST("incOrderStatus", order)
        if (res.result.nModified == 0) throw "incOrderStatus"
        this.$store.commit("incOrderStatus", order)
      } catch (e) {
        errorHandler(e, "Order niet gewijzigd")
      }
    },
    async orderStatus(order) {
      try {
        let arr = []
        let len = order.items.length
        let i = 0
        let status = 0
        for (i; i < len; i++) {
          arr.push(order.items[i].status)
        }
        let min = Math.min(...arr)
        let max = Math.max(...arr)
        if (max > 1 && min < 2) status = 1
        else status = min
        if (order.status === status) return status
        if (!(order.status < 3)) order.status = status
        let res = await fetchPOST("orderStatus", order)
        if (res.result.nModified == 0) throw "orderStatus"
        this.$store.commit("orderStatus", order)
        return status
      } catch (e) {
        errorHandler(e, "Order niet gewijzigd")
      }
    },
    findOrder(id) {
      for (let order of this.orders) {
        if (order._id === id) {
          return order
        }
      }
    },
    completeItem(item) {
      let confirmed = confirm("" + item.name + " voltooien?")
      if (confirmed) {
        let data = {
          item: item,
          route: this.selectedRoute,
          date: this.date
        }
        this.$store.commit("completeItem", data)
        this.$store.state.wss.send(
          JSON.stringify({ event: "completeItem", data: data })
        )
      }
    },
    isComplete(data) {
      if (data > 2) {
        return true
      } else {
        return false
      }
    },
    toCurrency(val) {
      return toCurrency(val)
    },
    toDateString(val) {
      return toDateString(val)
    },
    openOrderPaymentModal(order) {
      this.order = order
      this.orderPaymentModal = true
    },
    openInvoicePaymentModal(invoice) {
      this.invoice = invoice
      this.invoicePaymentModal = true
    },
    async printOrderLabel(order) {
      let filter = JSON.parse(JSON.stringify(order))
      let selected = filter.items.filter(item => item.status === 3)
      if (selected.length > 0) {
        filter.items = selected
        filter.itemIds = selected.map(item => item._id)
        this.$store.commit("orderLabelPrinted", filter)
        let buffer = await printOrderLabel(filter)
        let res = await fetchPOST("printOrderLabel", {
          buffer: buffer,
          copies: 1
        })
        let res2 = await fetchPOST("orderLabelPrinted", filter)
        console.log(res)
        console.log(res2)
        // let leftFilter = JSON.parse(JSON.stringify(order))
        // let left = leftFilter.items.filter(item => item.status < 3)
        // console.log(left.length)
        // if (left.length == 0) {
        //   let obj = this.findOrder(order._id)
        //   this.orderStatus(obj)
        //   console.log("orderstatus")
        // }
      } else alert("Nog geen items geselecteerd")
    },
    openGoogleMaps(item) {
      let url =
        "https://www.google.com/maps/search/?api=1&query=" +
        encodeURI(
          `${item.client.address},${item.client.postalcode},${item.client.city}`
        )
      window.open(url, "_blank")
    },
    toggleShowMeTheMoney() {
      localStorage.setItem(
        "showMeTheMoney",
        JSON.stringify(this.showMeTheMoney)
      )
    },
    addToDate(n) {
      this.allDates = -1
      let fromDate = new Date(this.fromDate)
      fromDate.setDate(fromDate.getDate() + n)
      this.fromDate = fromDate.toISOString().split("T")[0]
      this.fromDateFormatted = this.formatDate(this.fromDate)
      let toDate = new Date(this.toDate)
      toDate.setDate(toDate.getDate() + n)
      this.toDate = toDate.toISOString().split("T")[0]
      this.toDateFormatted = this.formatDate(this.toDate)
      this.changeDate()
    },
    emailOrders(){
      this.emailOrdersModal = true
    },
    async updateInvoiceFrequency(item){
try{
  let res = await fetchPOST("updateInvoiceFrequency", item)
  if (res.result.nModified == 0) throw "updateInvoiceFrequency"
  await this.updateOrder(item)
  successHandler("Factuur gewijzigd")
}
catch(e){
  errorHandler(e, "Factuur niet gewijzigd")
    }
  }
  },
  computed: {
    selectedClient() {
      return this.$store.state.selectedClient
    },
    orders() {
      let orders = this.$store.state.orders
      if (this.selectedStatus > -1)
        orders = orders.filter(order => order.status == this.selectedStatus)
      if (this.selectedRoute) {
        orders = orders.filter(order => order.route == this.selectedRoute)
      }
      return orders
    },
    categories() {
      let orders = this.$store.state.orders
      let categories = {}
      let items = []
      if (this.selectedRoute)
        orders = orders.filter(order => order.route == this.selectedRoute)
      for (let order of orders) {
        for (let item of order.items) {
          item.client = order.name
          item.order_id = order._id
          item.orderNumber = order.orderNumber
          items.push(item)
        }
      }
      if (this.selectedCategory) {
        items = items.filter(item => item.category == this.selectedCategory)
      }
      if (this.selectedStatus > -1) {
        items = items.filter(item => item.status == this.selectedStatus)
      }
      items = items.sort((a, b) => a.sort - b.sort)
      for (let item of items) {
        if (this.viewAggregate && this.allDates===false) {
          categories = categories || {}
          categories[item.name] = categories[item.name] || {}
          categories[item.name].name = item.name
          categories[item.name].assigned = item.assigned
          categories[item.name].amount = categories[item.name].amount || 0
          categories[item.name].totalWeight = categories[item.name].totalWeight || 0
          categories[item.name].amount =
            categories[item.name].amount +
              parseFloat(item.amount)
          categories[item.name].totalWeight =
            categories[item.name].totalWeight +
              parseFloat(item.totalWeight)
          categories[item.name].unit = item.unit
          categories[item.name].category = item.category
          categories[item.name].unitAmount = item.unitAmount
          categories[item.name].packaging = item.packaging
        } else {
          let cat = item.category
          categories = categories || {}
          categories[cat] = categories[cat] || {}
          categories[cat][item.name] = categories[cat][item.name] || {}
          categories[cat][item.name].items =
            categories[cat][item.name].items || []
          categories[cat][item.name].total =
            categories[cat][item.name].total || 0
          categories[cat][item.name].totalWeight =
            categories[cat][item.name].totalWeight || 0
          if (item.status < 2) {
            categories[cat][item.name].total =
              categories[cat][item.name].total +
                parseFloat(item.amount)
                categories[cat][item.name].totalWeight =
              categories[cat][item.name].totalWeight +
                parseFloat(item.totalWeight)
            categories[cat][item.name].items.push(item)
          }
        }
      }
      if (this.viewAggregate && !this.allDates) return categories
      return categories[this.selectedCategory]
    },
    totalItemsSold() {
      let items = this.$store.state.totalItemsSold
      items = items.sort((a, b) => a.sort - b.sort)
      return items
    },
    statuses() {
      let statuses = this.orderStatuses
      console.log("statuses")
      if (this.mounted && !this.allDates) {
        let orders = this.$store.state.orders
        if (this.selectedRoute)
          orders = orders.filter(order => order.route == this.selectedRoute)
        statuses.forEach(
          status =>
            (status.length = orders.filter(
              order => order.status == status.status
            ).length)
        )
      }
      return statuses
    },
    activeRoutes() {
      return this.$store.getters.activeRoutes
    },
    routes() {
      let routes = this.$store.state.settings.routes
      return routes.sort()
    },
    employees() {
      return this.$store.state.employees
    },
    totalWeight() {
      let totalWeight = 0
      if(this.categories){
        let arr = Object.values(this.categories)
        totalWeight = arr.reduce((acc, cur)=>{
          return acc + cur.totalWeight
        },0)
        }
      return totalWeight
    },
    totalCollis() {
      return 1
    },
    totalOrders() {
      let totalOrders =
        this.allDates && this.selectedStatus > -1
          ? this.statuses[this.selectedStatus].length
          : this.ordersLength
      return totalOrders
    },
    netTotal() {
      let netTotal = 0
      if (this.allDates) netTotal = this.orderNetTotal
      if (!this.allDates)
        netTotal = this.orders.reduce((acc, cur) => acc + cur.netTotal, 0)
      if (this.selectedOrders.length)
        netTotal = this.selectedOrders.reduce(
          (acc, cur) => acc + cur.netTotal,
          0
        )
      return netTotal
    },
    grossTotal() {
      let grossTotal = 0
      if (this.allDates) grossTotal = this.orderGrossTotal
      if (!this.allDates)
        grossTotal = this.orders.reduce((acc, cur) => acc + cur.grossTotal, 0)
      if (this.selectedOrders.length)
        grossTotal = this.selectedOrders.reduce(
          (acc, cur) => acc + cur.grossTotal,
          0
        )
      return grossTotal
    },
    debit() {
      let debit = 0
      if (this.allDates) debit = this.orderDebit
      if (!this.allDates)
        debit = this.orders.reduce((acc, cur) => acc + cur.debit, 0)
      if (this.selectedOrders.length)
        debit = this.selectedOrders.reduce((acc, cur) => acc + cur.debit, 0)
      return debit
    },
    openOrders() {
      let openOrdersGrossTotal = 0
      if (this.allDates) openOrdersGrossTotal = this.openOrdersGrossTotal
      if (!this.allDates)
        openOrdersGrossTotal = this.orders
          .filter(order => !order.invoiceNumber)
          .reduce((acc, cur) => acc + cur.debit, 0)
      return openOrdersGrossTotal
    },
    orderHeaders() {
      return this.$store.state.settings.orderHeaders
    }
  },
  mounted() {
    if (this.selectedClient.clientNumber) {
      this.searchString = this.selectedClient.name
      this.allDates = true
      this.selectedStatus = 3
      this.selectMinStatus = true
    }
    this.mounted = true
    this.fetchOrders()
    let showMeTheMoney = JSON.parse(localStorage.getItem("showMeTheMoney"))
    console.log(showMeTheMoney)
    if (showMeTheMoney !== null) {
      this.showMeTheMoney = showMeTheMoney
    }
  },
  components: {
    searchClient,
    datePicker,
    editItemModal,
    orderModal,
    printOrderModal,
    printOrdersModal,
    emailOrderModal,
    insertInvoiceModal,
    orderPaymentModal,
    invoicePaymentModal,
    promptModal,
    previewOrderModal,
    printTotalsModal,
    compareOrdersByDateModal,
    emailOrdersModal
  }
}
</script>
<style scoped>
#autocomplete-results {
  display: none;
}
.order,
.group {
  text-align: left;
  text-shadow: none;
  border: 5px solid rgb(226, 226, 226);
  margin: 10px;
  width: 225px;
  border-radius: 5px;
}
.statuses {
  display: inline-block;
  width: 30px;
  height: 30px;
  position: relative;
  vertical-align: middle;
}
.order th,
.order th a {
  color: #c41e3a;
  font-weight: bold;
}
.orders {
  text-decoration: none;
  padding: 5px;
}
.amount {
  width: 40px;
  height: 25px;
}
.item {
  width: 130px;
  height: 25px;
}
.unit {
  font-size: 0.8em;
  margin-left: -5px;
}
.priority {
  animation: blinker 1.5s linear infinite;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}
.sendAgain {
  margin-left: 10px;
  width: 20px;
}
.statusImage {
  height: 20px;
  padding: 2px;
}
.totals {
  font-size: 30px;
  text-align: left;
  margin: 0 0px 0 0;
  border-collapse: collapse;
  break-inside: avoid;
  page-break-inside: avoid;
  -webkit-column-break-inside: avoid;
}
.columns {
  column-count: 2;
}
.borders {
  border: 1px solid black;
}
.totals td {
  padding-right: 10px;
  width: 110px;
}
.totals td:nth-child(1) {
  width: 200px;
}
.totals td:nth-child(2) {
  font-weight: 900;
  font-size: 50px;
}
.incItemStatus {
  width: 15px;
  padding: 0 5px;
}
.crates {
  position: relative;
}
.crates span span {
  position: absolute;
  top: 0;
  right: 10px;
}
.statusBar {
  width: 60px;
}
.hide {
  display: none;
}
.show {
  display: table-row;
}
.arrow {
  width: 25px;
}
.actions {
  width: 30px;
  padding: 5px;
  vertical-align: super;
}
.clearBoth {
  clear: both;
}
.alignLeft {
  text-align: left;
}
.alignRight {
  text-align: right;
}
.selectedStatus {
  font-weight: bold;
  border: 5px solid #013fba;
}
.v-application .red,
.v-application .orange,
.v-application .light-green,
.v-application .black {
  color: white;
  border-color: #013fba !important;
}
::v-deep .v-badge__badge {
  color: white !important;
}
.overview-totals {
  text-align: center;
  font-size: 1.2em;
  font-weight: bold;
  padding: 10px;
}
.header-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.header-wrapper .date-wrapper .date-toggle {
  min-width: 100px;
  max-width: 100px;
  border: 1px solid #00000061;
  border-radius: 5px;
}
.header-wrapper .header-inner-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-self: center;
}
.header-wrapper .header-inner-wrapper > div {
  margin-right: 5px;
}
.header-wrapper .date-wrapper .search-date {
  min-width: 150px;
  max-width: 150px;
  margin-left: 5px;
}
.header-wrapper .header-inner-wrapper .search-client {
  min-width: 300px;
  max-width: 300px;
}
.header-wrapper .header-inner-wrapper .search-order {
  min-width: 180px;
  max-width: 200px;
}
.header-wrapper .header-inner-wrapper .search-route {
  min-width: 180px;
  max-width: 200px;
}
.header-wrapper .status-bar {
margin-top: -23px;
}
@media screen and (max-width: 1200px) {
  .header-wrapper .search-date {
    min-width: 100%;
    margin-bottom: 10px;
  }
  .header-wrapper .header-inner-wrapper .search-client {
    min-width: 100%;
    margin-bottom: 10px;
  }
  .header-wrapper .header-inner-wrapper .search-order {
    min-width: 100%;
    margin-bottom: 10px;
  }
  .header-wrapper .header-inner-wrapper .search-route {
    min-width: 100%;
    margin-bottom: 10px;
  }
  .header-wrapper .status-bar {
    margin-top: -5px;
}
}
.fieldset {
  border: 1px solid #00000061;
  border-radius: 5px;
  text-align: center;
  font-weight: bold;
  width: 100%;
  min-height: 70px;
  font-size: 1.2rem;
  background: #0000000f;
}
.fieldset textarea {
  width: 100%;
  height: 200px;
  border: none;
  resize: none;
  font-size: 1.2rem;
}
.fieldset-status {
  margin-top: -5px;
}
.legend {
  text-align: left;
  margin-left: 5px;
  padding-right: 3px;
  font-size: 13px;
  font-weight: 400;
}
::v-deep .v-text-field--filled:not(.v-text-field--single-line) input {
  margin: 0 !important;
}
.date-toggle button:nth-of-type(2) {
  border-top: 1px solid #00000061;
  border-bottom: 1px solid #00000061;
}
.header-wrapper .active-btn {
  background: #69a9e4;
}
.header-wrapper .date-wrapper {
  justify-content: center;
  align-self: center;
}
.header-wrapper .date-wrapper .add-to-date {
  width:100%;
}
</style>