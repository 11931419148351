<template>
  <v-dialog
    :value="emailOrdersModal"
    persistent
    fullscreen
  >
    <v-card>
        <v-card-title>Email order</v-card-title>
      <v-row>
        <v-col cols="6">
            <input
              class="hidden"
              id="input"
              @focus="closeEmailOrderModal"
              type="text"
            />
         <div class="settings">
           <label>Toon prijzen:</label
            ><input
              @change="viewOrders"
              type="checkbox"
              v-model="options.includePrices"
            />
            <label>Sjabloon:</label>
           <select @change="viewOrders" v-model="options.orderType">
             <option v-for="status of options.orderTypes" :value="status.status" :key="status.status">{{status.text}}</option>
           </select>
            <label>Aan:</label><textarea style="font-size:14px;" :value="emailAddresses"></textarea>
            <label>Bericht:</label>
            <vue-editor v-if="dataUrl" v-model="headers.html"></vue-editor>
          </div>
        </v-col>
        <v-col cols="6">
          <iframe
            :src="dataUrl"
            frameborder="0"
            id="iframe"
          ></iframe>
        </v-col>

        <v-footer fixed class="d-flex justify-center">
          <v-btn-toggle> 
            <v-btn @click="closeEmailOrderModal" color="primary"
              >Sluit</v-btn
            >
            <v-btn color="primary" @click="email">Mailen</v-btn>
          </v-btn-toggle>
        </v-footer>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { VueEditor } from "vue2-editor"
import { viewOrders,emailOrder } from "../js/pdfController"
import { errorHandler, fetchPOST, successHandler } from '../js/functions'
export default {
  name: "emailOrdersModal",
  props: {
    emailOrdersModal: Boolean,
    selectedOrders: Array
  },
  data() {
    return {
      dataUrl: "",
      iframeLoaded: false,
      options:{
        includePrices: false,
        orderType: 0,
        orderTypes:[
        {status:0,text:"Pakbon"},
        {status:1,text:"Opdrachtbevestiging"},
      ]
      },
      headers: {
        subject: "",
        fileName: "",
        to: "",
        text: "",
        html: ""
      },
    }
  },
  methods: {
    async email() {
      try {
        this.closeEmailOrderModal()
        for(let order of this.selectedOrders){
        let buffer = await emailOrder(
          order,
          this.options
        )
        this.headers.subject = `Pakbon ${order.orderNumber}`
        this.headers.fileName = `Pakbon ${order.orderNumber}.pdf`
        let response = await fetchPOST("sendMail", {
          buffer: buffer,
          headers: this.headers
        })
        if (response.success){
        let order = this.order
        order.emailed = true
        order.status = order.status <= 2 ? 2 : order.status
        await this.$store.dispatch("fetchPOST",{type:"orderEmailed",data:order})        
          successHandler("Email verzonden")
          }
          else throw "Email niet verzonden"
        }
      } catch (e) {
        errorHandler(e,"Email niet verzonden")
      }
    },
    closeEmailOrderModal() {
      URL.revokeObjectURL(this.dataUrl)
      this.$emit("close-email-order-modal")
    },
    async viewOrders() {
      let data = await viewOrders(this.selectedOrders, this.options)
      this.dataUrl = URL.createObjectURL(data)
    }
  },
  computed: {
    emailAddresses() {
      let emailAddresses = this.selectedOrders.map(order => order.client.email)
      return emailAddresses.join(",")
    }
  },
  async mounted() {
    // this.options.includePrices = this.order.client.includePrices
    // let client = await fetchGET("findClient",this.order.clientNumber)
    // this.headers.text = this.$store.state.settings.emailOrderBody.text
    // this.headers.html = this.$store.state.settings.emailOrderBody.html
    // this.headers.to = client.email
    await this.viewOrders(this.order, this.options)
  },
  components:{
    VueEditor
  }
}
</script>

<style scoped>
div.settings {
  justify-content: space-evenly;
  font-size: 2em;
  display: grid;
  grid-template-columns: min-content min-content;
  grid-gap: 5px;
}
input[type="checkbox"] {
  height: 1em;
  width: 1em;
}
#iframe {
  height: 700px;
  width: 600px;
  margin: auto;
  padding: 0;
  display: block;
}
.hidden {
  width: 0;
  height: 0;
  overflow: hidden;
}
</style>